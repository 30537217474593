import { Icon } from "../../../assets/images";

export const DashboardTopBar = () => {
  return (
    <div
      className="h-full"
      style={{
        display: "flex",
        backgroundColor: "#275B83",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingInline: "1.0vh",
        paddingBlock: "0.2vh",
      }}
    >
      {/* Left side section */}
      <div
        style={{ flexDirection: "row", alignItems: "center", display: "flex" }}
      >
        <a style={{ marginRight: "2.5vh" }}>
          <Icon.LeftArrow
            style={{
              height: "3vh",
              width: "3vh",
              objectFit: "contain",
            }}
          />
        </a>

        <a>
          <Icon.LeftArrow
            style={{
              height: "3vh",
              width: "3vh",
              objectFit: "contain",
              transform: "rotate(180deg)",
              opacity: 0.5,
            }}
          />
        </a>
        <a
          style={{
            marginLeft: "2.5vh",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon.DropDownArrow style={{ height: "1.0vh", width: "1.0vh" }} />

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Home
              style={{
                height: "2.0vh",
                width: "2.0vh",
                marginInline: "1.0vh",
              }}
            />
            <text
              className="text-size14 font-gsans font-medium"
              style={{ color: "white" }}
            >
              Patient Summary
            </text>
          </div>
        </a>
      </div>

      {/* Right side section */}
      <div
        style={{ flexDirection: "row", alignItems: "center", display: "flex" }}
      >
        <a style={{ marginRight: "1.5vh" }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.FullScreen
              style={{
                height: "2.0vh",
                width: "2.0vh",
                marginInline: "1.0vh",
              }}
            />
            <text
              className="text-size12 font-helve font-medium"
              style={{ color: "white" }}
            >
              Full screen
            </text>
          </div>
        </a>

        <a style={{ marginRight: "1.5vh" }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Print
              style={{
                height: "2.0vh",
                width: "2.0vh",
                marginInline: "1.0vh",
              }}
            />
            <text
              className="text-size12 font-helve font-medium"
              style={{ color: "white" }}
            >
              Print
            </text>
          </div>
        </a>

        <a style={{ marginRight: "1.5vh" }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Refresh
              style={{
                height: "2.0vh",
                width: "2.0vh",
                marginInline: "1.0vh",
              }}
            />
            <text
              className="text-size12 font-helve font-medium"
              style={{ color: "white" }}
            >
              4 minutes ago
            </text>
          </div>
        </a>
      </div>
    </div>
  );
};
