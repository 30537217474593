import React from "react";
import SideBar from "./sidebar/sidebar";
import Dashboard from "./dashboard/dashboard";

export default function Content({inputData} : {inputData : any}){
    return(
        <div className="flex" style={{height: "93vh"}}>
            <div className="w-2/12">
                <SideBar />
            </div>
            <div className="w-10/12">
                <Dashboard inputData={inputData}/>
            </div>
            <div>
                
            </div>
        </div>
    )
}