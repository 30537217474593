import React, { useEffect, useState } from "react";
import './Comorbidities.css'
import Report from "./report/Report";
import MultiSelectDropdown from "./report/comoSelect";
import { Icon } from "../../../assets/images";

interface Comorbidity {
    name: string;
    code: string;
    duration: string;
    age_identified: string;
    disabled?: boolean;
}

export default function Comorbidities({
    comorbidities,
    patientInformation
} : {comorbidities : any;patientInformation : any}) {
    const [selectedComorbidities, setSelectedComorbidities] = useState<Comorbidity[]>(comorbidities);

    const handleRemoveFromList = (option : Comorbidity) => {
        let updated = selectedComorbidities.map((selected) => selected.code === option.code ? ({...selected , disabled : true }) : selected);
        setSelectedComorbidities(updated);
    }
    
    return (
        <div className="flex h-full justify-evenly" style={{ width: "inherit" }}>
            <div className="h-full como-left-wrapper shadow-md" style={{ width: "26%" }}>
                <div className="flex" style={{ alignItems: 'center', justifyContent: 'space-between', padding: '0% 3%' }}>
                    <div className="comoheader text-size14 font-gsans font-bold">Comorbidities</div>
                    <div style={{width: '23%', display: 'inline-block'}}>
                        <MultiSelectDropdown
                            options={selectedComorbidities}
                            onSelectChange={setSelectedComorbidities}
                        />
                    </div>
                </div>
                <div className="comotabs">
                    <div className="flex relative">
                        <div className="visit text-size14 font-gsans font-medium">
                            Visit Info
                        </div>
                        <div className="visit-arrow"></div>
                    </div>
                    <div className=" flex flex-col" style={{ marginBlock: "1vh", maxHeight: '48vh', overflow: 'auto'}}>
                        {selectedComorbidities.filter(selected => !selected.disabled).map((comorbidity) => (
                            <div style={{display :'flex', alignItems : "center", justifyContent : "space-between"}} key={comorbidity.name}>
                            <div className="test text-size14 font-gsans font-medium" style={{ display: "flex", flexDirection: 'column', padding: "1vh" }}>
                                {comorbidity.name}
                                <text className="text-size10">{comorbidity.code} | {comorbidity.duration} | {comorbidity.age_identified}</text>
                            </div>

                            <a style={{width : '2vh', marginRight : '1vh', cursor: 'pointer'}} onClick={() => handleRemoveFromList(comorbidity)}>
                                <Icon.CloseIcon style={{height : '1.5vh', width : '1.5vh'}}/>
                            </a>

                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <div className="como-graph-wrapper como-left-wrapper shadow-md">
                <Report patientInformation={patientInformation}/>
            </div>
        </div>
    )
}