import React from "react";
import Comorbidities from "./comorbities/Comorbidities";
import { HealthStats } from "./healthStatistics";
import { DashboardTopBar } from "./TapBar";
import { ActivitySection } from "./Activity";

export default function Dashboard({ inputData }: { inputData: object }) {
  return (
    <div
      className="flex h-full"
      style={{
        height: "100%",
        backgroundColor: "#F7F7F7",
        flexDirection: "column",
      }}
    >
      <div>
        <DashboardTopBar />
      </div>
      <div style={{ height: "95%", flexDirection: "row", display: "flex" }}>
        <div id="Main-dashboard" style={{ width: "75%" }}>
          <div
            style={{
              height: "30%",
              paddingLeft: "1%",
              paddingTop: "1%",
              paddingRight: "0.5%",
            }}
          >
            <HealthStats patientVitals={inputData["vitals"]} />
          </div>
          <div style={{ height: "70%", paddingLeft: "0.7%" }}>
            <Comorbidities comorbidities={inputData["comorbidities"]} patientInformation={inputData["patient_information"]}/>
          </div>
        </div>
        <div style={{ width: "25%" }}>
          <ActivitySection
            lastVisitedInfo={inputData["last_visit_information"]}
            patientInformation={inputData["patient_information"]}
          />
        </div>
      </div>
    </div>
  );
}
