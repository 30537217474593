import React, { useEffect, useState } from 'react';
import './multiselectDropdown.css'
import DropDown from '../../../../../assets/commorbidities/drop down.svg'

interface Option {
  name: string;
  low: string | number;
  medium: string | number;
  high: string | number;
  color: string;
  type: number;
  disabled?: boolean;
}

interface MultiSelectDropdownProps {
  options: Option[];
  onSelectChange: (selectedOptions: string[]) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ options, onSelectChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(['LDL']);
  const [dropdownEnabled, setDropdownEnabled] = useState<boolean>(false);

  const handleOptionToggle = (option: Option) => {
    if (!dropdownEnabled || option.disabled) return;

    const selectedIndex = selectedOptions.indexOf(option.name);
    if (selectedIndex === -1) {
      setSelectedOptions([...selectedOptions, option.name]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  useEffect(() =>{
    onSelectChange(selectedOptions)
  },[selectedOptions])

  const isChecked = (option: Option) => selectedOptions.indexOf(option.name) !== -1;

  return (
    <div className="multi-select-dropdown text-size12" >
      <div 
        className='flex multi-menu justify-between' 
        style={{alignItems: 'baseline', cursor: 'pointer',overflow : 'hidden'}}
        onClick={() => setDropdownEnabled(!dropdownEnabled)}
        >
        <text style={{display : 'flex' , flexWrap : 'nowrap', height : '2vh'}} >
        {/* {selectedOptions.length > 0 ? 
          selectedOptions.map((data) =>{
            return `${data}, ` 
          })
        :  */}
        {selectedOptions.length ? selectedOptions.map((selected, index) => {
          return index === 0  ? selected : `, ${selected}`
        }) : "Select"}
        {/* } */}
      </text>
          <img src={DropDown} style={{height: '0.7%', width:'1.4vh', transform: dropdownEnabled ? 'rotate(180deg)' : ''}}/>
      </div>
      {dropdownEnabled && (
        <div className='multi-dropdown-select text-size12'>
          {options.map((option) => (
            <div key={option.name} className='flex' style={{width: '100%'}} onClick={() => handleOptionToggle(option)}>
              <div className='flex' style={{width: '80%'}}>
              <input
                type="checkbox"
                value={option.name}
                checked={isChecked(option)}
                disabled={option.disabled}
              />
              <label>{option.name}</label>
              </div>
              <div style={{margin: '1%', width: '15%', background: option.color}}>
                
              </div>
            </div>
          ))}
        </div>
      )}      
    </div>
  );
};

export default MultiSelectDropdown;
