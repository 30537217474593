import React, { useEffect, useState } from "react";
import ClinicalIntreaction from '../../../../assets/commorbidities/hospital.svg'
import Path from '../../../../assets/commorbidities/Path 29482.svg'
import Layer from '../../../../assets/commorbidities/Layer 2.svg'
import Analysis from '../../../../assets/commorbidities/analysis.svg'
import LabVitals from '../../../../assets/commorbidities/LabVitals.svg'
import './Report.css'
import { MyChart, TimeChart, TimeLineChart } from "./chart/Chart";
import MultiSelectDropdown from "./multiSelect/multiSelectDropdown";
import SampleData from '../../../../sampleData.json';
import { calculateLabelsForTimeFrame, generateTimestampsForTimeFrame } from "../../../../utils/graph";


export default function Report({
    patientInformation
} : {patientInformation : any}) {
    const timeFrame = ["1M", "3M", "6M", "1Y", "3Y", "5Y"]
    const [selectedIndex, setSelectedIndex] = useState(5);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const labParamsInfo = patientInformation["lab_params_info"];
    const [timeXAxisData, setTimeXAxisData] = useState(calculateLabelsForTimeFrame('5Y') || []);
    const [timeFilter, setTimeFilter] = useState(generateTimestampsForTimeFrame("5Y"));
    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    function timeStampGenerate (data, index){
        console.log('generateTimestampsForTimeFrame(data)  ', generateTimestampsForTimeFrame(data))
        setTimeFilter(generateTimestampsForTimeFrame(data))
        setSelectedIndex(index)
    }
    useEffect(() =>{
        setTimeXAxisData(calculateLabelsForTimeFrame(timeFrame[selectedIndex]))
    }, [selectedIndex])

    return (
        <div className="h-full">
            <div className="report-wrapper" >
                <div id="clinical-interactions" className="clinical-interaction" >
                    <div className="flex w-full justify-between" style={{ height: '20%' }}>
                        <div className="flex w-2/4 clinical-right-menu" style={{ alignItems: 'center' }}>
                            <img src={ClinicalIntreaction} className="clinical-img" />
                            <div className="clinical-text text-size14 font-gsans font-bold" style={{ paddingLeft: '1%' }}>CLINICIAN INTERACTIONS</div>
                        </div>
                        <div className="flex w-1/12 clinical-right-menu justify-evenly">
                            <img src={Path} className="clinical-Right-path" />
                            {/* <img src={Layer} className="clinical-Right-layer" /> */}
                        </div>
                    </div>
                    <div className="chart-wrapper" style={{ height: '80%' }}>
                        <TimeLineChart patientInformation={patientInformation} timeFilter={timeFilter}/>
                    </div>

                </div>
                <div id="lab-interactions" className="lab-interaction">
                    <div className="flex justify-between" style={{ height: '15%' }}>
                        <div className="flex clinical-right-menu" style={{ width: '40%' }}>
                            <img src={LabVitals} className="clinical-img" />
                            <div className="clinical-text text-size14 font-gsans font-bold" style={{ paddingLeft: '0.5%' }}>LABS / VITALS</div>
                        </div>
                        <div className="multiselect text-size10" style={{  maxWidth : '30%' }}>
                            <MultiSelectDropdown
                                options={labParamsInfo}
                                onSelectChange={handleSelectChange}
                            />
                        </div>
                    </div>
                    <div className="chart-wrapper" style={{ height: '80%' }}>
                        <MyChart selectedGraph={selectedOptions} timeFilter={timeFilter} patientInformation={patientInformation}  />
                    </div>

                </div>

                <div id="procedure-interactions" className="procedure-interaction">
                    <div style={{ height: '20%' }}>
                        <div className="flex clinical-right-menu">
                            <img src={Analysis} className="clinical-img" />
                            <div className="clinical-text text-size14 font-gsans font-bold" style={{ paddingLeft: '0.5%' }}>PROCEDURES</div>
                        </div>
                    </div>
                    <div className="chart-wrapper" style={{ height: '80%' }}>
                        <TimeChart timeFilter={timeFilter} patientInformation={patientInformation}/>
                    </div>

                </div>
                {/* <div style={{ paddingTop: '5%' }}></div> */}
            </div>
            <div style={{ height: '16%' }}>
                <div className="report-timeline text-size12 font-gsans" style={{ paddingBlock: "0.8vh" }}>
                    {
                        timeXAxisData.map((data) =>{
                            return (
                                <div>{data}</div>
                            )
                        })
                    }
                </div>
                <div className="timeline-series text-size11 font-gsans font-semibold">
                    {timeFrame.map((data, index) => {
                        const isSelected = selectedIndex === index;
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    timeStampGenerate(data, index)
                                }}
                                className="time"
                                style={{ background: isSelected ? '#12678A' : 'transparent', color: isSelected ? 'white' : '#5F5F5F' }}
                            >
                                {data}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}