import React from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "../../../assets/images";
import './reportNotesModel.css'
import SampleData from '../../../sampleData.json'

export function ReportNotesModel(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="flex justify-between">
                <Modal.Title className="text-size18">
                    Notes
                </Modal.Title>
                <button onClick={() => props.onHide()}>
                    <Icon.CloseIcon style={{height : '2.5vh', width : '2.5vh'}}/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={ {__html:SampleData.last_visit_information.view_notes_more}} />
            </Modal.Body>
        </Modal>
    )
}