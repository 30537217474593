export function generateRandomNumbers(min : number, max : number, count : number) {
    var randomNumbers: number[] = [];
    for (var i = 0; i < count; i++) {
        randomNumbers.push(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return randomNumbers;
};

export const generateTimestamps = (start: Date, count: number) => {
    const timestamps: string[] = []
    const current = new Date(start);
    for (let i = 0; i < count; i++) {
        timestamps.push(current.toISOString().slice(0, 10));
        current.setMonth(current.getMonth() + 1);
    }
    return timestamps;
};


interface GraphData {
    name: string,
    low: number,
    medium: number,
    high: number,
    color: string,
    tyoe: number,
    data: Array<any>
}

export const generateGraphTimeLineData = ({
    low,
    high,
    medium,
    graphDataValue,
} : {low : number, high : number, medium : number, startDate : Date, graphDataValue: GraphData,  endDate:Date}) => {

    const applyFormula = (input: number, low: number, medium: number, high: number): number => {
        if (input >= medium && input < high) {
            return 0; // Medium range
        } else if (input >= high) {
            return 1; // High range
        } else {
            return -1; // Low range
        }
    };
    const result = graphDataValue.data.map((data, index) =>{
        const graphData = {
            name: `${graphDataValue.name}: ${data[1]}`,
            value: [data[0], applyFormula(data[1], graphDataValue.low, graphDataValue.medium, graphDataValue.high)],
            tooltip:{
                formatter: '{b}'
            }
        }
        return graphData;
    })

    return result;
};


export const generateClinicianInteractionData =({
    data
}: {data: string}
) => {
    const formattedData = new Date(data);
    return [formattedData, 5]
}

export const toolTipHTmlData = ({
    data
}: {data: {type: string, name: string, symptoms: string, conclusion: string, notes: string, visit_date: string, summary: string}}) =>{
    const template = [
        {
            templateData: `<div class='card-content' style="color: white; padding-top: 1%; text-align: left; min-width: 45%;">
        <div style="display: flex; justify-content: space-between; width: 98%;">
            <div>
                <h3 style="color: white; text-transform: uppercase; font-size: 1.63vh;">${data?.name}</h3>
            </div>
            <div>
                <div style="background: #99EBA3; display: inline;color: #000000;font-size: 1.16vh;padding: 6%;border-radius: 0.4vh;">
                     View Details
                </div>
            </div>
        </div>
        
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh" >Symptoms:</strong> ${data?.symptoms}</p>
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Conclusion:</strong> ${data?.conclusion}</p>
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Notes:</strong> ${data?.notes}</p>
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Date:</strong> ${data?.visit_date}</p></div>`
    },
    {
        templateData: `<div class='card-content' style="color: white; padding-top: 1%; text-align: left;">
        <div style="display: flex; justify-content: space-between; width: 98%;">
            <div>
                <h3 style="color: white; text-transform: uppercase; font-size: 1.63vh;">${data?.name}</h3>
            </div>
            <div>
                <div style="background: #99EBA3; display: inline;color: #000000;font-size: 1.16vh;padding: 6%;border-radius: 0.4vh;">
                     View Details
                </div>
            </div>
        </div>
        
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh" >Summary:</strong> ${data?.summary}</p>
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Conclusion:</strong> ${data?.conclusion}</p>
        <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Date:</strong> ${data?.visit_date}</p></div>` 
    }
]
return data.type === '1' ? template[0].templateData :  template[1].templateData
}

export const procedureToolTipData = ({data}:{data: {name: string, summary: string, status: string, date: string, next_visit: string}}) =>{
    
    return `<div class='card-content' style="color: white; padding-top: 1%; text-align: left; min-width: 45%;">
    <div style="display: flex; justify-content: space-between; width: 98%;">
        <div>
            <h3 style="color: white; text-transform: uppercase; font-size: 1.63vh;">${data.name}</h3>
        </div>
        <div>
            <div style="background: #99EBA3; display: inline;color: #000000;font-size: 1.16vh;padding: 6%;border-radius: 0.4vh;">
                 View Details
            </div>
        </div>
    </div>
    
    <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh" >Summary:</strong> ${data.summary}</p>
    <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Status:</strong>${data.status}</p>
    <div>
    <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Date:</strong> ${data.date}</p></div>
    <p style="color: white; font-size: 1.16vh" ><strong style="color: #DBEBEF; font-weight: 400; font-size: 1.16vh">Next Visit:</strong> ${data.next_visit}</p></div>
    </div>`
}

export function generateTimestampsForTimeFrame(selectedTimeFrame: string): { fromDate: string; toDate: string } {
    const today: Date = new Date();
    let fromDate: Date = new Date();

    switch (selectedTimeFrame) {
        case '1M':
            fromDate.setTime(today.getTime() - (30 * 24 * 60 * 60 * 1000));
            break;
        case '3M':
            fromDate.setTime(today.getTime() - (3 * 30 * 24 * 60 * 60 * 1000));
            break;
        case '6M':
            fromDate.setTime(today.getTime() - (6 * 30 * 24 * 60 * 60 * 1000));
            break;
        case '1Y':
            fromDate.setTime(today.getTime() - (365 * 24 * 60 * 60 * 1000));
            break;
        case '3Y':
            fromDate.setTime(today.getTime() - (3 * 365 * 24 * 60 * 60 * 1000));
            break;
        case '5Y':
            fromDate.setTime(today.getTime() - (5 * 365 * 24 * 60 * 60 * 1000));
            break;
        default:
            break;
    }

    // Convert dates to string in the format YYYY-MM-DD
    const fromDateStr: string = fromDate.toISOString().split('T')[0];
    const toDateStr: string = today.toISOString().split('T')[0];

    console.log('FromDate', fromDate);
    console.log('toDateStr', toDateStr);
    return {
        fromDate: fromDateStr,
        toDate: toDateStr
    };
}

interface LabelCalculation {
    [key: string]: (currentDate: Date) => Date;
}

function calculateLabels(startDate: Date, endDate: Date, numLabels: number, format: string): string[] {
    const delta = (endDate.getTime() - startDate.getTime()) / (numLabels - 1);
    let labels: string[] = Array.from({ length: numLabels }, (_, i) => {
        const labelDate = new Date(startDate.getTime() + i * delta);
        return formatDate(labelDate, format);
    });
    return labels.reverse();
}

function formatDate(date: Date, format: string): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    switch (format) {
        case "month":
            return `${monthNames[date.getMonth()]} ${day}`;
        case "yearMonth":
            return `${monthNames[date.getMonth()]} ${year}`;
        default:
            return "";
    }
}

const labelCalculations: LabelCalculation = {
    "1M": (currentDate: Date) => new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000),
    "3M": (currentDate: Date) => new Date(currentDate.getTime() - 3 * 30 * 24 * 60 * 60 * 1000),
    "6M": (currentDate: Date) => new Date(currentDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000),
    "1Y": (currentDate: Date) => new Date(currentDate.getTime() - 365 * 24 * 60 * 60 * 1000),
    "3Y": (currentDate: Date) => new Date(currentDate.getTime() - 3 * 365 * 24 * 60 * 60 * 1000),
    "5Y": (currentDate: Date) => new Date(currentDate.getTime() - 5 * 365 * 24 * 60 * 60 * 1000)
};

export function calculateLabelsForTimeFrame(timeFrame: string): string[] {
    const currentDate = new Date();
    const format = (timeFrame === "1Y" || timeFrame === "3Y" || timeFrame === "5Y") ? "yearMonth" : "month";
    const endDate = labelCalculations[timeFrame](currentDate);
    return calculateLabels(currentDate, endDate, 9, format);
}