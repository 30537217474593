
export default function Header({patientDetails} : {patientDetails  :any}) {
  return (
    <div
      className="flex w-full"
      style={{
        height: "7vh",
        backgroundColor: "#3976A5",
        display: "flex",
        justifyContent: "space-between",
        alignItems : 'flex-end',
        paddingInline : '2vh',
        paddingBlock : '1vh'
      }}
    >
      <div style={{display : 'flex', flexDirection : 'column'}}>
           <text className="text-size18 font-helve font-medium" style={{textAlign : 'left', color : 'white'}}>{patientDetails.name}</text>
           <text className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>Admission Weight: {patientDetails.admission_weight}</text>
      </div>

      <div style={{display : 'flex', flexDirection : 'column'}}>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>Sex: {patientDetails.sex}</text>
      </div>

      <div style={{display : 'flex', flexDirection : 'column'}}>
           <text className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>Age: {patientDetails.age}</text>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>EMR: {patientDetails.emr}</text>
      </div>

      <div style={{display : 'flex', flexDirection : 'column'}}>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>DOB: {patientDetails.dob}</text>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>PA: {patientDetails.pa}</text>
      </div>

      <div style={{display : 'flex', flexDirection : 'column'}}>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>Loc: {patientDetails.loc}</text>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>{patientDetails.outpatient}</text>
      </div>

      <div style={{display : 'flex', height : '100%'}}>
           <text  className="text-size12 font-helve font-medium" style={{textAlign : 'left',  color : 'white'}}>** Allergies Not Recorded **</text>
      </div>
    </div>
  );
}
