import { useState } from "react";
import { Icon, images } from "../../../assets/images";
import { ReportNotesModel } from "./reportNotesModel";

const ActivityInfoText = ({ title, value }) => {
  return (
    <text
      className="text-size12 font-gsans"
      style={{ textAlign: "left", paddingBlock: "1.0vh" }}
    >
      {title + ": "}
      <span className="text-size12 font-gsans font-semibold" style={{ textAlign: "left", fontWeight: "700" }}>
        {value}
      </span>
    </text>
  );
};

const InsightSelectionMenu = ({ selectedIndex, onSelect }) => {
  const options = ["Medications", "Symptoms", "Recent Reports"];

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#F5F5F5",
        borderTopLeftRadius: "1.4vh",
        borderTopRightRadius: "1.4vh",
        padding: "1.0vh",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {options.map((option, index) => {
        const isSelected = selectedIndex === index;
        return (
          <a
            style={{
              borderRadius: "0.7vh",
              width: "33%",
              paddingInline: "0.4vh",
              paddingBlock: "0.3vh",
              backgroundColor: isSelected ? "#14678A" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => onSelect(index)}
          >
            <text
              className="text-size12 font-gsans font-medium"
              style={{
                color: isSelected ? "white" : "#9A9A9A",
              }}
            >
              {option}
            </text>
          </a>
        );
      })}
    </div>
  );
};

const Insights = ({ name, desc, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "1.5vh",
      }}
    >
      {icon}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "1.5vh",
        }}
      >
        <text className="text-left text-size11">{name}</text>
        <text className="text-left text-gray-400 text-size10">{desc}</text>
      </div>
    </div>
  );
};

export const ActivitySection = ({
  lastVisitedInfo,
  patientInformation
} : {lastVisitedInfo : any; patientInformation : any}) => {
  const medication = patientInformation["medication"];
  const symptoms = patientInformation["symptoms"];
  const recentReports = patientInformation["recent_reports"];
  const allergies = patientInformation["allergies"];

  const [selectedInsightInd, setSelectedInsightInd] = useState(0);
  const [modalShow, setModalShow] = useState(false);


  const renderMedicationSection = (data : Array<{type : string, name : string, details : string}>, hideIcon : boolean) => {
    return data.map((insight, index) => (
      <Insights
        name={insight.name}
        desc={insight.details}
        icon={ hideIcon ?
          undefined :
          index % 2 === 0 ? (
            <Icon.Medicine style={{ height: "3vh", width: "3vh" }} />
          ) : (
            <img
              src={images.dashboard.medical}
              style={{ height: "2vh", width: "2vh" }}
            />
          )
        }
      />
    ));
  }

  const getTabViewData = () => {
    switch(selectedInsightInd) {
      case  0 : 
       return medication;
      case  1 : 
      return symptoms;
      case 2 : 
      return recentReports;
       default:
        return medication;
    }
  };

  return (
    <div
      className="h-full"
      style={{ display: "flex", margin: "2%", flexDirection: "column" }}
    >
      {/* Last Visited Info */}
      <div
        className="shadow-md"
        style={{
          display: "flex",
          borderRadius: "1.8vh",
          backgroundColor: "#FFFFFF",
          padding: "1.0vh",
          position: "relative",
          flexDirection: "column",
          marginBottom: "1.0vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <text
            className="text-size15 font-gsans font-medium" style={{ textAlign: "left", marginLeft : "1vh" }}
          >
            Last Visit Information
          </text>

          <a
            style={{
              borderWidth: "1px",
              borderColor: "#C7DDD9",
              borderRadius: "2.0vh",
              paddingInline: "1.0vh",
              paddingBlock: "0.7vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => setModalShow(true)}
          >
            <Icon.ViewReport
              style={{ marginRight: "1.0vh", height: "1.5vh", width: "1.5vh" }}
            />
            <text
             className="text-size11 font-gsans font-semibold"
              style={{
                fontWeight: "600",
                textAlign: "left",
                color: "#12678A",
              }}
            >
              View Notes
            </text>
          </a>
        </div>

        <div
          style={{
            backgroundColor: "#F2FAF9",
            borderBottomLeftRadius: "1.4vh",
            borderBottomRightRadius: "1.4vh",
            padding: "1.5vh",
            marginTop: "1.0vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ActivityInfoText
            title={"Symptoms"}
            value={lastVisitedInfo.symptoms}
          />
          <ActivityInfoText
            title={"Conclusion"}
            value={lastVisitedInfo.conclusion}
          />
          <ActivityInfoText title={"Notes"} value={lastVisitedInfo.notes} />
          <ActivityInfoText title={"Date"} value={lastVisitedInfo.visit_date} />
        </div>
      </div>

      {/* Quick insights */}
      <div
        className="shadow-md"
        style={{
          display: "flex",
          borderRadius: "1.8vh",
          backgroundColor: "#FFFFFF",
          padding: "1.0vh",
          position: "relative",
          flexDirection: "column",
        }}
      >
        <InsightSelectionMenu
          selectedIndex={selectedInsightInd}
          onSelect={setSelectedInsightInd}
        />

        <div
          className="scroll-smooth"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBlock: "1.0vh",
            maxHeight : '20vh',
            minHeight : '15vh',
            overflow : 'auto'
          }}
        >
          {renderMedicationSection(getTabViewData(),selectedInsightInd !== 0)}
        </div>
      </div>

      {/* Allergies */}
      <div
        className="shadow-md"
        style={{
          display: "flex",
          borderRadius: "1.8vh",
          backgroundColor: "#FFFFFF",
          paddingInline: "1.5vh",
          paddingBlock: "1.0vh",
          position: "relative",
          flexDirection: "column",
          marginTop: "1.0vh",
        }}
      >
        <text
          className="text-size15 font-gsans font-medium"
          style={{ textAlign: "left" }}
        >
          Allergies
        </text>
        <div
         className="scroll-smooth"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBlock: "1.0vh",
            maxHeight : '20vh',
            minHeight : '15vh',
            overflow : 'auto'
          }}
        >
          {renderMedicationSection(allergies, false)}
        </div>
      </div>
      <ReportNotesModel 
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};
