import { useEffect, useState } from "react";
import "./App.css";
import Header from "./header/Header";
import Content from "./content/Content";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import ConfigEditor from "./configEditor";
import SampleData from "./sampleData.json";

function DashBoardScreen({inputData } : {inputData : string}) {
  const parsedData = JSON.parse(inputData);
  return (
    <div>
      <Header patientDetails={parsedData["patient_details"]}/>
      <Content inputData = {parsedData}/>
    </div>
  );
}

function App() {
  const [configuration, setConfig] = useState<string>(localStorage.getItem("lda_config") ?? JSON.stringify(SampleData, null, "\t"));

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DashBoardScreen inputData={configuration}/>} />
          <Route
            path="/config"
            element={
              <ConfigEditor
                data={configuration}
                onSaveConfig={(updatedConfig) => {
                    setConfig(updatedConfig);
                    localStorage.setItem("lda_config", updatedConfig);
                }}
                resetConfig ={() =>{
                  setConfig(JSON.stringify(SampleData, null, "\t"));
                  localStorage.setItem("lda_config", JSON.stringify(SampleData, null, "\t"));
                }}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
