import React, { useEffect, useState } from 'react';
import './comoSelect.css'
import SampleData from '../../../../sampleData.json'

interface Comorbidity {
    name: string;
    code: string;
    duration: string;
    age_identified: string;
    disabled?: boolean;
}

interface MultiSelectDropdownProps {
    options: Comorbidity[];
    onSelectChange: (selectedOptions: Comorbidity[]) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ options, onSelectChange }) => {
    const [selectedOptions, setSelectedOptions] = useState<Comorbidity[]>([]);
    const [dropdownEnabled, setDropdownEnabled] = useState<boolean>(false);

    useEffect(() => {
        setSelectedOptions(options);
    },[options])

    const handleOptionToggle = (index : number) => {
        // if (!dropdownEnabled || option.disabled) return;

        let updated = selectedOptions.map((selected, sIndex) => index === sIndex ? ({...selected , disabled : !selected.disabled }) : selected);
        setSelectedOptions(updated);
    };

    const handleAllToggle = () => {
        if (!dropdownEnabled) return;

        if (selectedOptions.length === options.length) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions([...options]);
        }
    };

    const handleSave = () => {
        onSelectChange(selectedOptions);
        setDropdownEnabled(!dropdownEnabled)
    };

    // const handleCancel = () => {
    //     // Reset selected options or handle any other cancel logic
    //     setSelectedOptions([]);
    // };

    return (
        <div className="select-dropdown text-size12">
            <div onClick={() => { setDropdownEnabled(!dropdownEnabled) }}>Select</div>

            {dropdownEnabled && (
                <div className="multi-selec-dropdown" >
                    <div>
                        <div className='options'>
                            {selectedOptions.map((option, oIndex) => (
                                <div key={option.name} className='flex' onClick={() => handleOptionToggle(oIndex)}>
                                    <input
                                        type="checkbox"
                                        value={option.name}
                                        onChange={() => handleOptionToggle(oIndex)}
                                        checked={!option.disabled}
                                    // disabled={option.disabled}
                                    />
                                    <div className="w-full test text-size14 font-gsans font-medium" style={{ display: "flex", flexDirection: 'column', padding: "1vh" }}>
                                        {option.name}
                                        <text className="text-size11">{option.code} | {option.duration} | {option.age_identified}</text>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{display : 'flex', justifyContent : 'flex-end', paddingInline : '1vh', paddingBlock : '0.5vh'}}>
                            <div className='buttons-display'>
                                <button className='button-cancel font-gsans text-size9' onClick={()=> setDropdownEnabled(!dropdownEnabled)}>Cancel</button>
                                <button className='button-save font-gsans text-size9' onClick={handleSave}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default MultiSelectDropdown;
