//@ts-nocheck
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import './Chart.css';
import KidneyIcon from '../../../../../assets/commorbidities/Kidney.svg'
import CircleBorderBlue from '../../../../../assets/commorbidities/circle-border-blue.svg'
import SquareInnerCircle from '../../../../../assets/commorbidities/Square-inner-circle.svg'
import MRIScan from '../../../../../assets/commorbidities/MRIScan.svg'
import XRay from '../../../../../assets/commorbidities/XRay.svg'
import RedCircle from '../../../../../assets/commorbidities/RedCircle.svg'
import ProcedureIcon from '../../../../../assets/commorbidities/ProcedureIcon.svg'
import { generateClinicianInteractionData, generateGraphTimeLineData, generateTimestamps, procedureToolTipData, toolTipHTmlData } from '../../../../../utils/graph';

interface MyChart {
    selectedGraph?: any;
    patientInformation? : any
    timeFilter?: any
  }

export const MyChart: React.FC<MyChart> = ({
    selectedGraph,
    patientInformation,
    timeFilter
}) => {
    const [labParamsInfo, SetLabParamsInfo] = useState(patientInformation["lab_params_info"])
    useEffect(() =>{
        const filtered = patientInformation["lab_params_info"].filter((data) => selectedGraph.includes(data.name));
        SetLabParamsInfo(filtered)
    },[selectedGraph])
    
    const startDate = new Date('2020-03-01');
    const timestamps = generateTimestamps(startDate, 16);


    useEffect(() => {
        console.log(labParamsInfo,'labParamsInfo')
        const chart = echarts.init(document.getElementById('myChart')!);
        console.log('timeFilter MyCharts', timeFilter)
        chart.setOption({
            xAxis: {
                type: 'time',
                // data: timestamps,
                show: false,
                min: timeFilter.fromDate,
                max: timeFilter.toDate,
            },
            yAxis: {
                type: 'value',
                data: ['L', 'M', 'H'],
                min: -1,
                max: 1,
                splitNumber: 1,
                axisLabel: {
              show: true,
              formatter: function (value, index) {
                return `${value === -1 ? 'L' : value === 0 ? 'M' : 'H'}`;
              },
              textStyle: {
                color: function (value, index) {
                  return index === 0 || index === 2 ? 'red' : 'green';
                }
              }
            },
            axisLine:{
              onZero: true,
              onZeroAxisIndex: 1
            }
            },
            tooltip:{
                show: true
          },
            grid: {
                height: '70%',
                left: '5%',
                top: '5%',
                width: '100%'
            },
            series: labParamsInfo.filter((data) => selectedGraph.includes(data.name)).map((labParams) => ({
                data: generateGraphTimeLineData({ low: labParams.low, medium: labParams.medium, high: labParams.high,  graphDataValue: labParams}),
                type: 'line',
                smooth: true,
                symbol: 'circle', // Use a circle symbol for data points
                symbolSize: 7, // Set the size of the symbol
                itemStyle: {
                    color: labParams.color, // Change the color of the data points
                    // borderColor: '#EB99F4', // Change the border color of the data points
                    borderWidth: 2, // Change the border width of the data points
                    padding: '2%',
                    backgroundColor: 'white'
                }
            }))
        },true);
    }, [labParamsInfo, selectedGraph, timeFilter]);

    return <div id="myChart" style={{ width: '100%', height: '100%' }} />;
};

export const TimeLineChart: React.FC<MyChart> = ({timeFilter, patientInformation}) => {
    const generateClinicianInteractionIcons = ({
        iconType,
        icon,
        patientInformation
    }: { iconType: string, icon: boolean; }) => {
        const IconImageStrings = [
            {
                type: '1',
                icon: `image://${CircleBorderBlue}`,
                symbolSize: 10,
            },
            {
                type: '3',
                icon: `image://${KidneyIcon}`,
                symbolSize: 20,
            },
            {
                type: '2',
                icon: `image://${SquareInnerCircle}`,
                symbolSize: 12,
            },
            {
                type: '5',
                icon: `image://${MRIScan}`,
                symbolSize: 20,
            },
            {
                type: '4',
                icon: `image://${XRay}`,
                symbolSize: 20,
            }
        ]
        const iconData = IconImageStrings.filter((data) => data.type === iconType) || IconImageStrings[0].icon
        return icon ? iconData[0].icon : iconData[0].symbolSize;
    }
    const data = patientInformation["clinician_interactions"];
    const graphDataConstruct = data.map(data => {
        const finalData = {
            value: generateClinicianInteractionData({ data: data.visit_date }),
            symbol: `${generateClinicianInteractionIcons({ iconType: data.type, icon: true })}`,
            symbolSize: generateClinicianInteractionIcons({ iconType: data.type, icon: false }),
            tooltip: {
                formatter: function () {
                    return toolTipHTmlData({data});
                },
                backgroundColor: '#18383E',
                textStyle: {
                    color: '#ffff'
                },
                extraCssText: 'min-width: 45%;',
                borderColor: '#18383E'
            }
        }
        return finalData
    })

    useEffect(() => {
        const timelineChart = echarts.init(document.getElementById('myTimeChart')!);
        timelineChart.setOption({
            xAxis: {
                type: 'time',
                show: false,
                min: timeFilter.fromDate,
                max: timeFilter.toDate,
            },
            yAxis: {
                type: 'value',
                show: false
            },
            grid: {
                height: '100%',
                left: '2%',
                top: '30%',
                width: '100%'
            },
            tooltip: {
                show: true,
                triggerOn: 'click',
                renderMode: 'html',
                trigger: 'item',
                axisPointer: {
                    type: 'line',
                    snap: true
                },
                enterable: true,
                position: function (point, params, dom, rect, size) {
                    return [point[0], '60%']
                }
            },
            series: [
                {
                    data: graphDataConstruct,
                    type: 'line'
                }
            ],
            color: '#d6d9da'
        })
    })
    return (
        <div style={{ height: '100%' }}>
            {/* <div id="cardContainer" style={{ position: 'absolute', display: 'none', backgroundColor: 'white', border: '1px solid black', padding: '10px' }}></div> */}
            <div id="myTimeChart" style={{ width: '100%', height: '100%', paddingTop: "" }} />
        </div>
    );
};

export const TimeChart: React.FC<MyChart> = ({timeFilter, patientInformation}) => {
    const procedureList = patientInformation["procedures"];
    const procedureData = procedureList.map(data =>{
        const template = 
        {
            value: generateClinicianInteractionData({data: data.date}),
            symbol: `image://${ProcedureIcon}`,
            symbolSize: 15,
            symbolOffset: [0, '-15%'],
            tooltip: {
                formatter: function () {
                    return procedureToolTipData({data});
                },
                backgroundColor: '#18383E',
                textStyle: {
                    color: '#ffff'
                },
                extraCssText: 'min-width: 45%;',
                borderColor: '#18383E'
            }
        }
        return template
    })
    
    useEffect(() => {
        const timelineChart = echarts.init(document.getElementById('mTimeChart')!);
        timelineChart.setOption({
            xAxis: {
                type: 'time',
                min: timeFilter.fromDate,
                max: timeFilter.toDate,
                show: false
            },
            yAxis: {
                type: 'value',
                show: false
            },
            grid: {
                height: '100%',
                left: '2%',
                top: '30%',
                width: '100%'
            },
            tooltip: {
                show: true,
                triggerOn: 'click',
                renderMode: 'html',
                trigger: 'item',
                // hideDelay: 1000,
                axisPointer: {
                    type: 'line',
                    snap: true
                },
                enterable: true,
                // alwaysShowContent: true,
                position: function (point, params, dom, rect, size) {
                    return [point[0], '25%']
                }
            },
            series: [
                {
                    data: procedureData,
                    type: 'line',
                    colorBy: "data",
                }
            ],
            color: '#d6d9da',
        })
    })
    return (
        <div className='relative'>
            <div id="mTimeChart" style={{ width: '100%', height: '100%', paddingTop: "" }} />
        </div>
    );
};

// option = {
//     xAxis: {
//       type: 'time',
//       show: false
//     },
//     yAxis: {
//       type: 'value',
//       min: -1,
//       max: 1,
//       splitNumber: 1,
//       axisLabel: {
//         show: true,
//         formatter: function (value, index) {
//           return `${value === -1 ? 'L' : value === 0 ? 'M' : 'H'}`;
//         },
//         textStyle: {
//           color: function (value, index) {
//             return index === 0 || index === 2 ? 'red' : 'green';
//           }
//         }
//       },
//       axisLine:{
//         onZero: true,
//         onZeroAxisIndex: 1
//       }
//     },
//     tooltip:{
//       show: true
//     },
//     series: [
//       {
        
//         data: [
//          {
//            name: 'LDL: 290',
//            value:  ['2018-10-12', 1],
//            tooltip:{
//               formatter: '{b}'
//            }
//          },
//           ['2019-10-12', 0],
//           ['2020-10-12', -1],
//           ['2021-10-12', 0],
//           ['2022-10-12', 0]
//           ],
//         type: 'scatter'
//       }
//     ]
//   };
  

// option = {
//     xAxis: {
//       type: 'time',
//       show: false
//     },
//     yAxis: {
//       type: 'value',
//       min: -1,
//       max: 1,
//       splitNumber: 1,
//       axisLabel: {
//         show: true,
//         formatter: function (value, index) {
//           return `${value === -1 ? 'L' : value === 0 ? 'M' : 'H'}`;
//         },
//         textStyle: {
//           color: function (value, index) {
//             return index === 0 || index === 2 ? 'red' : 'green';
//           }
//         }
//       },
//       axisLine:{
//         onZero: true,
//         onZeroAxisIndex: 1
//       }
//     },
//     tooltip:{
//       show: true
//     },
//     series: [
//       {
        
//         data: [
//          {
//            name: 'LDL: 290',
//            value:  ['2018-10-12', 1],
//            tooltip:{
//               formatter: '{b}'
//            }
//          },
//           ['2019-10-12', 0],
//           ['2020-10-12', -1],
//           ['2021-10-12', 0],
//           ['2022-10-12', 0]
//           ],
//         type: 'scatter'
//       }
//     ]
//   };
  

// option = {
//     xAxis: {
//       type: 'time',
//       show: false,
//       min: '2018-01-01',
//       max: '2020-04-15',
//       splitNumber: 9
//     },
//     yAxis: {
//       type: 'value',
//       // min: 5,
//       // max: 8,
//       scale: false,
//       // axisLine:{
//       //   offset: false
//       // }
//     },
//     series: [
//       {
//         data: [
//           // ['1970-10-10', 5],
//           // ['2018-01-01', 1],
//           // ['2019-01-01', 5],
//           ['2020-01-02', 5],
//           ['2021-01-01', 5],
//           ['2022-01-01', 5],
//           ['2023-01-01', 5],
//           ['2024-01-01', 5],
//           ['2024-01-01', 5],
//           ['2018-04-01', 5]
//           ],
//         type: 'scatter'
//       }
//     ]
//   };

// option = {
//     xAxis: {
//       type: 'time',
//       show: false
//     },
//     yAxis: {
//       type: 'value',
//       min: -1,
//       max: 1,
//       splitNumber: 1,
//       axisLabel: {
//         show: true,
//         formatter: function (value, index) {
//           return `${value === -1 ? 'L' : value === 0 ? 'M' : 'H'}`;
//         },
//         textStyle: {
//           color: function (value, index) {
//             return index === 0 || index === 2 ? 'red' : 'green';
//           }
//         }
//       },
//       axisLine:{
//         onZero: true,
//         onZeroAxisIndex: 1
//       }
//     },
//     tooltip:{
//       show: true
//     },
//     series: [
//       {
        
//         data: [
//          {
//            name: 'LDL: 290',
//            value:  ['2018-10-12', 1],
//            tooltip:{
//               formatter: '{b}'
//            }
//          },
//           ['2019-10-12', 0],
//           ['2020-10-12', -1],
//           ['2021-10-12', 0],
//           ['2022-10-12', 0]
//           ],
//         type: 'scatter'
//       }
//     ]
//   };
  