import React, { useState } from "react";
import { Icon } from "../../assets/images";
export default function SideBar(){
    const [selectedMenu,setSelectedMenu] = useState<{label : string; value : number; showAddButton? : boolean}>({label : 'Patient Summary', value : 3});
    const sideMenuOptions : Array<{label : string; value : number; showAddButton? : boolean}> = [
        {label : 'Hospitalist Workflow', value : 1},
        {label : 'Interdisciplinary Results Summary', value : 2},
        {label : 'Patient Summary', value : 3},
        {label : 'Procedure Day Summary', value : 4},
        {label : 'Orders', value : 5, showAddButton : true},
        {label : 'Document Viewing', value : 6,showAddButton : true},

        {label : 'Results', value : 7},
        {label : 'Clinical Notes', value : 8,showAddButton : true},
        {label : 'IAF/I&O', value : 9},
        {label : 'Diagnosis & Problems', value : 10},
        {label : 'Procedure/Family History', value : 11},
        {label : 'Medication List', value : 12,showAddButton : true},

        {label : 'Reference', value : 13},
        {label : 'Patient Information', value : 14},
        {label : 'Allergies', value : 15,showAddButton : true},
        {label : 'Microbiology Viewer', value : 16},
        {label : 'Procedure/Family History', value : 17},
        {label : 'Depart', value : 18}
    ];


    return (
        <div className="h-full" style={{ backgroundColor : '#4D6068', position : 'relative'}}>
           <div style={{ backgroundColor : '#293A4C', display : 'flex',alignItems : 'center', paddingInline : '1.0vh', justifyContent :'space-between', paddingBlock : '0.5vh'}}>
              <text className="text-size14 font-helve font-bold" style={{color : 'white', fontWeight : 'bold', textAlign : 'left'}}>Menu</text>
              <Icon.Pin 
              style={{height : '1.5vh', width : '1.5vh'}}/>
           </div>

           {/* Menu */}
           <div style={{display : 'flex', overflow : 'hidden',flexDirection : 'column', height : '95%'}}>
                {sideMenuOptions.map((option ) => (
                    <div style={{display :'flex', justifyContent : 'space-between', alignItems : 'center', paddingInline : '1.0vh', paddingBlock : '0.7vh', backgroundColor : option.value === selectedMenu.value ? "#2383C4" : ""}}>
                         <text className="text-left text-size12 font-helve font-medium" style={{color : 'white', fontWeight : '500'}}>{option.label}</text>
                         {option.showAddButton && <Icon.Add style={{height : '2vh',width : '2vh', marginRight : '1.5vh'}}/>}
                    </div>
                ))}
           </div> 
        </div>
    )
}