import AceEditor from "react-ace";
import {useNavigate} from 'react-router-dom';

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { useState } from "react";

function ConfigEditor({
  data,
  onSaveConfig,
  resetConfig
}: {
  data: string;
  onSaveConfig: (updatedConfig : string) => void;
  resetConfig: () => void;
}) {
  const [config, onChangeConfig] = useState<string>(data);
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        padding: "3vh",
      }}
    >
      <text
        className="text-size24 font-gsans font-medium text-left"
        style={{ marginBottom: "2vh" }}
      >
        Edit Configuration
      </text>
      <AceEditor
        height={`${window.innerHeight * 0.7}px`}
        width={`${window.innerWidth * 0.8}px`}
        placeholder="Placeholder Text"
        mode="java"
        theme="monokai"
        name="LAD_CONFIG_EDITOR"
        onChange={onChangeConfig}
        fontSize={14}
        showPrintMargin={false}
        showGutter={false}
        highlightActiveLine={false}
        value={config}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: false,
          tabSize: 2,
        }}
      />

      <div className="flex" style={{ marginTop: "2vh" }}>
        <button
          className="font-gsans font-medium text-size14"
          style={{
            backgroundColor: "green",
            color: "white",
            padding: "1vh",
            borderRadius: "0.5vh",
            marginRight: "2vh",
          }}
          onClick={() => {
            onSaveConfig(config);
            navigate("/");
        }}
        >
          Save & Update
        </button>
        <button
          className="font-gsans font-medium text-size14"
          style={{
            backgroundColor: "lightgrey",
            padding: "1vh",
            borderRadius: "0.5vh",
            width: "10vh",
          }}
          onClick={() => navigate("/")}
        >
          Cancel
        </button>
      </div>
      <div className="flex" style={{ marginTop: "2vh" }}>
      <button
          className="font-gsans font-medium text-size14"
          style={{
            backgroundColor: "rgb(20, 103, 138)",
            color: "white",
            padding: "1vh",
            borderRadius: "0.5vh",
            marginRight: "2vh",
          }}
          onClick={() => {
            resetConfig()
            navigate("/");
        }}
        >
          Reset Local Storage
        </button>
      </div>
    </div>
  );
}

export default ConfigEditor;
