import React from "react";
import { Icon, images } from "../../../assets/images";
import "../../../App.css";

interface HealthStatsProps {
  temperature?: number;
  bp?: string;
  pulse?: number;
  spO2Level?: number;
  height?: number;
  weight?: number;
  bmi?: number;
}

interface HealthInfoWidgetProps {
  icon?: any;
  title?: string;
  value?: string;
  scale?: "°F" | "mmHg" | "bpm" | "%";
  hideBorder?: boolean;
}

const HealthInfoWidget = ({
  icon,
  scale = "bpm",
  title,
  value,
  hideBorder,
}: HealthInfoWidgetProps) => {
  const showScaleText = scale === "bpm" || scale === "mmHg";
  return (
    <div
      style={{
        width: "25%",
        borderRightWidth: hideBorder ? 0 : 0.5,
        borderRightStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRightColor: "#BBC1C4",
        flexDirection: "row",
      }}
    >
      <img src={icon} style={{height : '4.5vh', width : '4.5vh'}} />
      <div style={{ padding: 0, marginLeft : '1.0vh' }}>
        <p
         className="text-size15 font-gsans"
          style={{
            margin: 0,
            color: "#333333",
            textAlign :'left',
          }}
        >
          {title}
        </p>
        <p className="text-size20 font-gsans font-medium" style={{ margin: 0,textAlign : 'left' }}>
          {showScaleText ? value : `${value}${scale}`}
          {showScaleText ? (
            <span
              className="text-size12"
              style={{
                color: "#6B6B6B",
                margin: 0,
                marginLeft : '1.5vh'
              }}
            >
              {scale}
            </span>
          ) : null}
        </p>
      </div>
    </div>
  );
};

const HeightAndWeightBanner = ({
  title,
  value,
  bgColor,
}: {
  title?: string;
  value?: string;
  bgColor?: string;
}) => {
  return (
    <div
      style={{
        backgroundColor: bgColor ?? "#79CDBE",
        width: "30%",
        display: "flex",
        flexDirection: "row",
        borderRadius: "0.8vh",
        marginRight: "1.0vh",
        justifyContent: "space-around",
        alignItems: "center",
        padding : '1.0vh'
      }}
    >
      <p className="text-size15">{title}</p>

      <div
        style={{
          marginLeft: "1.0vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          {Array(9)
            .fill("")
            .map((_, index) => {
              const isPointer = index === 4;
              return (
                <div
                  style={{
                    height: isPointer ? "1.0vh" : "0.5vh",
                    width: "0.1vh",
                    backgroundColor: isPointer ? "#0AB196" : "#0C3740",
                    marginRight: "1.0vh",
                  }}
                />
              );
            })}
        </div>

        <p className="text-size18" style={{ margin: 0}}>{value}</p>
      </div>
    </div>
  );
};

const BMIIndicator = ({bmi} : {bmi : number}) => {
  const bmiPinValues = [15, 18.5, 25, 30, 40];
  const startingRange = bmiPinValues[0];
  const endingRange = bmiPinValues[bmiPinValues.length - 1];
  const dividend = endingRange - startingRange;
  const actualGap = bmi - startingRange;
  const actualPercentage = (actualGap / dividend) * 100 - ((bmi < 40) ? 1 : 3);

  return (
    <div
      style={{
        backgroundColor: "#4F8590",
        width: "40%",
        display: "flex",
        borderRadius: "0.8vh",
        flexDirection: "row",
        paddingBlock: "0.2vh",
        alignItems: "center",
        justifyContent: "space-between",
        paddingInline : '1.0vh'
      }}
    >
      <div
        style={{
          width: "30%"
        }}
      >
        <p className="text-size12" style={{ margin: 0, color: "white", textAlign : 'left' }}>
          BMI
          <span className="text-size18"  style={{ marginLeft: "1.5vh" }}>{bmi}</span>
        </p>
      </div>
      
      {/* Indicator View */}
      <div style={{ width: "70%", position : 'relative', marginBottom : '0.7vh'}}>
      <img style={{height : '2.0vh', width : '2.0vh', objectFit : "contain", margin : 0, position : 'absolute', left : `${actualPercentage - 2.3}%`, top : '-0.5vh'}} src={images.dashboard.anchorPin} />
        <div style={{ display: "flex", flexDirection: "column", marginTop : '1.2vh', position :'relative'}}>
          <div
            className="Dashboard-BMI-Indicator"
            style={{
              height: "1.0vh",
              borderRadius: "0.7vh",
              backgroundColor: "#B9D3EC",
              alignItems : 'center',
              // justifyContent : "center",
              display : "flex",
              position : 'relative'
            }}
          >
             <div style={{height : '0.7vh', width : '0.7vh', borderRadius : "0.35vh", backgroundColor :"white", borderWidth : '0.05vh',borderColor : "#0050618F", borderStyle :"solid", left : `${actualPercentage}%`,position : 'absolute'}}/>
            </div>

          <div style={{display : 'flex', flexDirection : 'row', marginTop : '0.2vh', position : 'relative', width : '97%', backgroundColor :'red'}}>
             {bmiPinValues.map((bmiPinValue) => {
              return(
                 <p className="text-size10" style={{margin : 0, color : 'white',position : 'absolute', left : `${((bmiPinValue - startingRange)/dividend) * 100}%`}}>{bmiPinValue}</p>
              )
             })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const HealthStats = ({patientVitals}: {patientVitals : any}) => {

  return (
    <div
     className="shadow-md"
      style={{
        backgroundColor: "white",
        borderRadius: "1.4vh",
      }}
    >
      <div
        style={{
          backgroundColor: "#99EBA330",
          borderRadius: "1.4vh",
          padding : '2vh'
        }}
      >
        <div
          style={{
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <HealthInfoWidget
            title={"Temperature"}
            value={patientVitals.temperature}
            scale="°F"
            icon={images.dashboard.temp}
          />

          <HealthInfoWidget
            title={"Blood Pressure"}
            value={patientVitals.blood_pressure}
            scale="mmHg"
            icon={images.dashboard.bpm}
          />
    
          <HealthInfoWidget
            title={"Pulse"}
            value={patientVitals.pulse}
            scale="bpm"
            icon={images.dashboard.pulse}
          />
          <HealthInfoWidget
            title={"SpO2"}
            value={patientVitals.spo2}
            scale="%"
            hideBorder
            icon={images.dashboard.spo2}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2.0vh",
            paddingRight: "3.0vh",
          }}
        >
          <span
            className="text-size10 font-helve"
            style={{ color: "#6B6B6B", marginLeft: "1.0vh" }}
          >
            Last updated: {patientVitals.last_updated_datetime}
          </span>
        </div>
      </div>

      <div className="flex flex-row" style={{padding : '2.0vh'}}>
        <HeightAndWeightBanner title="Height" value={patientVitals.height}/>
        <HeightAndWeightBanner title="Weight" value={patientVitals.weight} bgColor="#B5EEBD" />
        <BMIIndicator bmi={parseFloat(patientVitals.bmi ?? "15")}/>
      </div>
    </div>
  );
};
